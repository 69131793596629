<template>
  <div class="my-4" style="min-height: 660px;">
    <el-alert class="mb-2 text-left" type="info" show-icon :closable="false" title="错题重做功能说明" description="选择对应的学科、等级及题目类型，即可对之前做错的题目重新答题。如果错题集中没有题目，则不会生成重做试卷。重做正确的题目会消除错误记录。"></el-alert>
    <div class="flex flex-wrap items-center">
        <span>错题重做：</span>
        <el-form :inline="true" :model="form" class="flex flex-wrap">
          <el-form-item label="学科" class="mb-0">
            <el-select v-model="form.type" placeholder="学科" size="small">
              <el-option v-for="item of filterType" :key="item.val" :label="item.name" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="等级" class="mb-0">
            <el-select v-model="form.level" placeholder="等级" size="small">
              <el-option v-for="item of levelList" :key="item.val" :label="item.name" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" class="mb-0">
            <el-select v-model="form.question_type" placeholder="类型" size="small">
              <el-option v-for="item of quesTypeList" :key="item.val" :label="item.name" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  class="mb-0">
            <el-button type="warning" size="small" :loading="generateLoading" @click="generate" icon="el-icon-document-add">开始重做</el-button>
          </el-form-item>
        </el-form>
    </div>
    <el-divider></el-divider>
    <div class="type-list flex flex-wrap items-center">
      <span class="mb-2">学科分类：</span>
      <el-button type="warning" :plain="typeAction(item.val)" size="small" v-for="item of typeList" :key="item.val" class="mb-2" @click="selectType(item)">{{ item.name }}</el-button>
    </div>
    <div class="ques-type-list flex flex-wrap items-center">
      <span class="mb-2">问题类型：</span>
      <el-button type="primary" :plain="quesAction(item.val)" size="small" v-for="item of quesTypeList" :key="item.val" class="mb-2" @click="selectQuesType(item)">{{ item.name }}</el-button>
    </div>
    <div class="list-wrap text-sm" v-if="quesList.length > 0">
      <el-card v-for="item of quesList" :key="item.id" class="w-full mb-4 text-left" shadow="hover">
        <div class="flex flex-wrap">
          <div class="md:w-2/5 w-full pr-2" v-html="item.title">
          </div>
          <div class="md:w-1/5 w-1/2">
            <p class="mb-2" v-if="typeName(item.type)">学科：{{ typeName(item.type) }}</p>
            <p class="mb-2" v-if="quesName(item.question_type)">类型：{{ quesName(item.question_type) }}</p>
            <p class="mb-2 flex flex-wrap">难度：<el-rate v-model="item.difficult" disabled></el-rate></p>
          </div>
          <div class="md:w-1/5 w-1/2">
            <p class="mb-4">错误次数：<span class="inline-block px-1 bg-red-400 text-white rounded">{{ item.num }}</span></p>
            <p class="mb-4">最近作答时间：{{ item.update_time }}</p>
          </div>
          <div class="md:w-1/5 w-full flex justify-end items-start">
            <el-button type="primary" size="small" @click="openDetail(item.id)">查看详情</el-button>
          </div>
        </div>
      </el-card>
      <el-pagination
        class="my-4 md:hidden"
        background
        small
        layout="total, prev, pager, next"
        :page-size="query.num"
        :total="quesTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-pagination
        class="my-4 md:block hidden"
        background
        layout="total, prev, pager, next"
        :page-size="query.num"
        :total="quesTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-empty v-else description="你还没有错题喔～"></el-empty>
  </div>
</template>

<script>
import { list, generateRetest } from '@/api/wrong_set'
export default {
  name: 'WrongSetList',
  data() {
    return {
      typeList: [
        { name: '全部', val: -1 },
        { name: 'Scratch', val: 0 },
        { name: 'C++', val: 1 },
        { name: 'Python', val: 2 },
        { name: 'NOIP', val: 3 },
        { name: '机器人', val: 4 },
      ],
      quesTypeList: [
        { name: '全部', val: -1 },
        { name: '选择题', val: 0 },
        { name: '判断题', val: 1 },
        { name: '多选题', val: 2 },
        { name: '求解题', val: 3 },
        { name: '编程题', val: 4 },
        { name: '问答题', val: 5 }
      ],
      levelList: [
        { name: '一级', val: 1 },
        { name: '二级', val: 2 },
        { name: '三级', val: 3 },
        { name: '四级', val: 4 }
      ],
      query: {
        type: -1,
        ques_type: -1,
        page: 1,
        num: 8
      },
      quesList: [],
      quesTotal: 0,
      currentPage: 1,
      form: {
        type: 0,
        question_type: -1,
        level: 1
      },
      generateLoading: false
    }
  },
  computed: {
    filterType() {
      return this.typeList.filter(item => {
        return item.val !== -1
      })
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    typeAction(val) {
      if (this.query.type === val) {
        return !true
      }
      return !false
    },
    quesAction(val) {
      if (this.query.ques_type === val) {
        return !true
      }
      return !false
    },
    selectType(item) {
      this.query.type = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    selectQuesType(item) {
      this.query.ques_type = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    typeName(val) {
      return this.typeList.find(item => {
        return item.val === val
      })?.name
    },
    quesName(val) {
      return this.quesTypeList.find(item => {
        return item.val === val
      })?.name
    },
    init() {
      list(this.query).then(res => {
        this.quesList = res.data.list
        this.quesTotal = res.data.count
      })
    },
    openDetail(id) {
      this.$router.push({ path: '/wrong-set/detail', query: { id } })
    },
    // 生成重做题目
    generate() {
      this.generateLoading = true
      generateRetest(this.form).then(res => {
        this.$confirm('【重做试卷】生成成功，是否要现在开始考试呢？', '提示', {
          confirmButtonText: '现在开始',
          cancelButtonText: '稍后到【模拟题】中考试'
        }).then(() => {
          const hrefData = this.$router.resolve({ 
            path: '/exam/detail',
            query: { id: res.data.id }
          })
          window.open(hrefData.href, 'exam-page')
        })
        this.generateLoading = false
      }).catch(() => {
        this.generateLoading = false
      })
    }
  }
}
</script>